import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { getSessionClient } from "../../../api"

export default function Upload() {
    const { t } = useTranslation()

    const [file, setFile] = useState<File>()
    const [uploading, setUploading] = useState(false)
    const [uploadingPercent, setUploadingPercent] = useState(0)
    const [uploadError, setUploadError] = useState(false)
    const [duplicateFile, setDuplicateFile] = useState(false)
    const [uploadComplete, setUploadComplete] = useState(false)

    function handleSingleFile(data: any) {
        if (!data?.target?.files?.length) { return }
        setFile(data.target.files[0])
    }
    async function handleUpload() {
        if (!file || uploading) { return }
        const fileToUpload = file
        setUploadError(false)
        setUploading(true)
        setUploadingPercent(0)
        setDuplicateFile(false)
        setUploadComplete(false)
        try {
            const data = await fileToUpload.arrayBuffer()
            const sessionClient = getSessionClient()
            const s3Key = await sessionClient.uploadFile({
                data,
                contentType: fileToUpload.type,
                onUploadProgress: (e: any) => setUploadingPercent(Math.trunc(e.loaded * 100 / e.total))
            })
            const { isDuplicate } = await sessionClient
                .call<{ isDuplicate: boolean }>("upload", {                    
                    s3Key,
                    filename: fileToUpload.name,
                    lastModified: fileToUpload.lastModified
                })
            setDuplicateFile(isDuplicate)
            setUploadComplete(true)
            /*
                At this point, we might want code like this:
                if (!isDuplicate) { navigate("/thank-you") }
            */
        }
        catch {
            setUploadError(true)
        }
        setUploading(false)
    }

    return (
        <div className="TEMPLATE-Upload">
            <h1>{t("Upload Something!")}</h1>
            <p>
                <label>{t("Choose a picture file")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="image/*" />
            </p>
            <p>
                <label>{t("Choose a video file")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="video/*" />
            </p>
            <p>
                <label>{t("Take a picture [mobile only]")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="image/*" capture />
            </p>
            <p>
                <label>{t("Take a video [mobile only]")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="video/*" capture />
            </p>
            <p>
                <label>{t("Take a picture (prefer user-facing camera) [mobile only]")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="image/*" capture="user" />
            </p>
            <p>
                <label>{t("Take a video (prefer user-facing camera) [mobile only]")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="video/*" capture="user" />
            </p>
            <p>
                <label>{t("Take a picture (prefer back-facing camera) [mobile only]")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="image/*" capture="environment" />
            </p>
            <p>
                <label>{t("Take a video (prefer back-facing camera) [mobile only]")}</label>
                <br />
                <input onChange={handleSingleFile} type="file" accept="video/*" capture="environment" />
            </p>
            <p>
                <button onClick={handleUpload} disabled={uploading || !file}>{t("Upload")}</button>
                {uploading &&
                    <span>{uploadingPercent}% {t("complete")}</span>
                }
                {uploadError &&
                    <span>{t("The connection was lost while uploading, please try again")}</span>
                }
                {uploadComplete &&
                    <span>{t("Upload complete... Duplicate File = ")}{duplicateFile.toString()}</span>                    
                }
            </p>
            <p><Link to="/stuff-to-do">{t("Back")}</Link></p>
        </div>
    )
}