import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { handleSessionKey } from "./helpers/querystring"
import handleWwwRedirect from './helpers/wwwRedirect/handleWwwRedirect'
import CustomRoutes from "./Config/Routes"
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import App from './App'
import Pages from './Pages'

handleSessionKey()
handleWwwRedirect()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Pages />}>
                    {CustomRoutes}
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
