import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { useRequiredCampaignState } from "../../helpers/campaignStates"

export default function Open() {
    useRequiredCampaignState("open")
    const { t } = useTranslation()
    return (
        <div className="TEMPLATE-Open">
            <div>
                <p>{t("I am a container for content, and can be accessed only while the campaign is 'open'")}</p>
                <div className="TEMPLATE-Open-Content">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}