import React, { useState, useEffect } from "react"
import { getSessionClient } from "../../api"
import { Navigate } from "react-router-dom"

export default function CampaignAuthRequired(props: { children: React.ReactNode }) {
    const sessionClient = getSessionClient()
    
    const [authed, setAuthed] = useState(null as boolean | null)

    async function checkAuth() {
        try {
            const auth = await sessionClient.isLoggedIn()
            if (auth !== authed) { setAuthed(auth) }
        }
        catch (e) {
            console.log('Failed to contact API to check auth status')
            setAuthed(false)
        }
    }

    useEffect(() => {
        if (authed === null) { checkAuth() }
    })

    if (authed === false) { 
        return <Navigate to="/login" replace />
    }
    if (authed === null) {
        return <></>
    }
    return <>{props.children}</>
}