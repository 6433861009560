import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { emailRegex } from "../../../helpers/regexes"
import { useState } from "react"
import { campaignClient, getSessionClient, setSessionClient } from "../../../api"

type RegisterFormData = { 
    firstName: string
    lastName: string
    email: string
    rules: boolean 
    optin1: boolean 
}

export default function Registration() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }} = useForm<RegisterFormData>()
    
    const [submitting, setSubmitting] = useState(false)
    const [submissionError, setSubmissionError] = useState(false)

    async function handleRegister(data: RegisterFormData) {
        if (submitting) { return }
        setSubmitting(true)
        setSubmissionError(false)
        try {
            const { sessionKey } = await getSessionClient()
                .createParticipantBuilder()
                .setFirstName(data.firstName)
                .setLastName(data.lastName)
                .setEmail(data.email)
                .addMetadata({
                    rules: data.rules,
                    optin1: data.optin1
                })
                .call<{ sessionKey?: string }>("register")
            if (sessionKey) {
                setSessionClient(campaignClient.recoverSession(sessionKey))
                navigate("/stuff-to-do")
            }
            else {
                setSubmissionError(true)
            }
        }
        catch {
            setSubmissionError(true)
        }
        setSubmitting(false)
    }

    return (
        <div className="TEMPLATE-Registration">
            <h1>{t("Register Now!!")}</h1>
            <p>{t("This page is for returning users")}</p>
            <form onSubmit={handleSubmit(handleRegister)}>
                <label>{t("First Name")}</label>
                <input {...register("firstName", { required: true })} placeholder={t("First Name") ?? ""} />
                {errors.firstName && <span className="TEMPLATE-Registration-error">{t("First name is missing")}</span>}
                <br />

                <label>{t("Last Name")}</label>
                <input {...register("lastName", { required: true })} placeholder={t("Last Name") ?? ""} />
                {errors.lastName && <span className="TEMPLATE-Registration-error">{t("Last name is missing")}</span>}
                <br />

                <label>{t("Email")}</label>
                <input {...register("email", { required: true, pattern: emailRegex })} placeholder={t("Email") ?? ""} type="email" />
                {errors.email && <span className="TEMPLATE-Registration-error">{t("Email is missing/invalid")}</span>}
                <br />

                <label>{t("Agree to eternal slavery")}</label>
                <input {...register("rules", { required: true })} type="checkbox" />
                {errors.rules && <span className="TEMPLATE-Registration-error">{t("You must agree")}</span>}
                <br />

                <label>{t("Agree to sell firstborn")}</label>
                <input {...register("optin1", { required: true })} type="checkbox" />
                {errors.optin1 && <span className="TEMPLATE-Registration-error">{t("You must agree")}</span>}
                <br />
                
                <input type="submit" disabled={submitting} />
                {submissionError && 
                    <>
                    <br />
                    <span className="TEMPLATE-Registration-error">{t("Error connecting to the server, please try again")}</span>
                    </>
                }
            </form>
            <p><Link to="/returning" replace>{t("I've already registered")}</Link></p>
        </div>
    )
}