import { Route } from "react-router-dom"
import Admin from "../Pages/Admin"
import Closed from "../Pages/Closed"
import ComingSoon from "../Pages/ComingSoon"
import Login from "../Pages/Login"
import Logout from "../Pages/Logout"
import Open from "../Pages/Open"
import FanMail from "../Pages/Open/FanMail"
import InstantWin from "../Pages/Open/InstantWin"
import Landing from "../Pages/Open/Landing"
import Registration from "../Pages/Open/Registration"
import Returning from "../Pages/Open/Returning"
import StuffToDo from "../Pages/Open/StuffToDo"
import Upload from "../Pages/Open/Upload"
import RedemptionOnly from "../Pages/RedemptionOnly"
import Suspended from "../Pages/Suspended"
import Version from "../Pages/Version"
import AdminLanding from "../Pages/Admin/Landing"

import "../i18n"

export default
    <>
        <Route path="/" element={<Open />}>
            <Route path="/" element={<Landing />} />
            <Route path="register" element={<Registration />} />
            <Route path="returning" element={<Returning />} />
            <Route path="stuff-to-do" element={<StuffToDo />} />
            <Route path="upload" element={<Upload />} />
            <Route path="instant-win" element={<InstantWin />} />
            <Route path="fan-mail" element={<FanMail />} />
        </Route>
        <Route path="admin" element={<Admin />}>
            <Route path="/admin" element={<AdminLanding />} />
        </Route>
        <Route path="closed" element={<Closed />} />
        <Route path="coming" element={<ComingSoon />} />
        <Route path="redemption" element={<RedemptionOnly />} />
        <Route path="suspended" element={<Suspended />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="spry/version" element={<Version />} />
    </>