import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function Landing() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div className="TEMPLATE-Landing">
            <h1>{t("Welcome to the SPRY Template")}</h1>
            <p>{t("This is a landing page")}</p>
            <p><button onClick={() => navigate("/register")}>{t("Register Now")}</button></p>
            <p><button onClick={() => navigate("/returning")}>{t("I'm Returning")}</button></p>
            <p><button onClick={() => navigate("/admin")}>{t("Admin stuff")}</button></p>
        </div>
    )
}