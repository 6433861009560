import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { getSessionClient } from "../../../api"
import ReactModal from "react-modal"

export default function InstantWin() {
    const { t } = useTranslation()

    const [won, setWon] = useState(null as boolean | null)
    const [inProgress, setInProgress] = useState(false)
    const [error, setError] = useState(false)
    const [tooFast, setTooFast] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    async function handlePlay() {
        if (inProgress) { return }
        setInProgress(true)
        setError(false)
        setTooFast(false)
        setWon(null)
        try {
            const { win, tooFast } = await getSessionClient().call<{ win: boolean, tooFast: boolean }>("instantwin", {})
            setWon(tooFast ? null : win)
            setTooFast(tooFast)
            setModalOpen(true)
        }
        catch (e) {
            setError(true)
        }
        setInProgress(false)
    }

    return (
        <div className="TEMPLATE-InstantWin">
            <button onClick={handlePlay} disabled={inProgress}>{t("Click to see if you win (max once per minute)")}</button>
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}>
                {won !== null &&
                    <p>
                        {t("Congratulations! You are an instant:")}{" "}
                        {won ? t("winner") : t("loser")}
                    </p>
                }
                {tooFast &&
                    <p>{t("You're playing too fast")}</p>
                }
                <button onClick={() => setModalOpen(false)}>{t("Close")}</button>
            </ReactModal>
            {error &&
                <p>{t("An error occured, please try again")}</p>
            }
            <p><Link to="/stuff-to-do">{t("Back")}</Link></p>
        </div>
    )
}