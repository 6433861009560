import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function StuffToDo() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div className="TEMPLATE-StuffToDo">
           <h1>{t("Ok, what would you like to do?")}</h1>
           <p><button onClick={() => navigate("/upload")}>{t("Upload something")}</button></p>
           <p><button onClick={() => navigate("/instant-win")}>{t("Instantly win something")}</button></p>
           <p><button onClick={() => navigate("/fan-mail")}>{t("Send some fan mail")}</button></p>
        </div>
    )
}