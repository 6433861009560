import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { emailRegex } from "../../../helpers/regexes"
import { campaignClient, getSessionClient, setSessionClient } from "../../../api"
import { useState } from "react"

type ReturningFormData = {
    email: string
}

export default function Returning() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }} = useForm<ReturningFormData>()

    const [submitting, setSubmitting] = useState(false)
    const [noSuchEmail, setNoSuchEmail] = useState(false)
    const [submissionError, setSubmissionError] = useState(false)

    async function handleReturningUser(data: ReturningFormData) {
        if (submitting) { return }
        setSubmitting(true)
        setNoSuchEmail(false)
        setSubmissionError(false)
        try {
            const { sessionKey } = await getSessionClient()
                .createParticipantBuilder()
                .setEmail(data.email)
                .call<{ sessionKey?: string }>("returning")
            if (sessionKey) {
                setSessionClient(campaignClient.recoverSession(sessionKey))
                navigate("/stuff-to-do")
            }
            else {
                setNoSuchEmail(true)
            }
        }
        catch {
            setSubmissionError(true)            
        }
        setSubmitting(false)
    }

    return (
        <div className="TEMPLATE-Returning">
            <h1>{t("Welcome Back!")}</h1>
            <p>{t("This page is for returning users")}</p>
            <form onSubmit={handleSubmit(handleReturningUser)}>
                <label>{t("Email")}</label>
                <input {...register("email", { required: true, pattern: emailRegex })} placeholder={t("Email") ?? ""} type="email" />
                {errors.email && <span className="TEMPLATE-Returning-error">{t("Email is missing/invalid")}</span>}
                <br />
                <input type="submit" disabled={submitting} />
                {submissionError && 
                    <>
                    <br />
                    <span className="TEMPLATE-Returning-error">{t("Error connecting to the server, please try again")}</span>
                    </>
                }
                {noSuchEmail &&
                    <>
                    <br />
                    <span className="TEMPLATE-Returning-error">{t("No such email, please register")}</span>
                    </>
                }
            </form>
            <p><Link to="/register" replace>{t("Register instead")}</Link></p>
        </div>
    )
}