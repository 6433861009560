import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

export default function Pages() {
    const { t } = useTranslation()

    return (
        <div className="TEMPLATE-App">
            <header className="TEMPLATE-App-header">
                {t("App Header Goes Here")}
            </header>
            <section className="TEMPLATE-App-content">
                <Outlet />
            </section>
            <section className="TEMPLATE-App-footer">
                {t("App Footer Goes Here")}
            </section>
        </div>
    )
}