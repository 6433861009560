import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { getSessionClient } from "../../../api"

type FanMailData = {
    word: string
}

export default function FanMail() {
    const { t } = useTranslation()

    const [sending, setSending] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm<FanMailData>()
    const [sendError, setSendError] = useState(false)

    async function handleSendFanMail(data: FanMailData) {
        if (sending) { return }
        setSending(true)
        setSendError(false)
        try {
            await getSessionClient().call("fanmail", { word: data.word })
        }
        catch {
            setSendError(true)
        }
        setSending(false)
    }

    return (
        <div className="TEMPLATE-FanMail">
            <h1>{t("Send some fan mail")}</h1>
            <form onSubmit={handleSubmit(handleSendFanMail)}>                
                <label>{t("Secret Word")}</label>
                <input {...register("word", { required: true })} />
                {errors.word && <span>{t("Secret word is required!")}</span>}
                <br />
                <input type="submit" disabled={sending}></input>
            </form>
            {sendError && 
                <p>{t("Error sending...")}</p>
            }
        </div>
    )
}